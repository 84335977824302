
import CounterIndicator from "@/components/Dashboard/CounterIndicator.vue";
import SalesTable from "@/components/Dashboard/SalesTable.vue";
import ShipmentSummaryReport from "@/components/Dashboard/ShipmentSummaryReport.vue";
import BucketsTable from "@/components/Dashboard/BucketsTable.vue";
import PrintQueueTable from "@/components/Dashboard/PrintQueueTable.vue";
import {DashboardStatus} from "@/models/DashboardStatus";
import {Component, Vue} from 'vue-property-decorator';
import AnimatedNumber from "@/components/AnimatedNumber.vue";
import moment from "moment";
import {BvTableProviderCallback} from "bootstrap-vue";
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import axios from "axios";

@Component({
    components: {
        CounterIndicator, SalesTable, BucketsTable, AnimatedNumber, ShipmentSummaryReport, PrintQueueTable
    }
})
export default class Dashboard extends Vue {

    private totalRevenues: Record<string, number | null> = {};
    private totalFills: Record<string, number | null> = {};
    private totalRevenueAggregate: number = 0;

    private tableItems: Array<any> | BvTableProviderCallback = []; // Define the tableItems variable here

    formatNumber(number: number): number {
        return parseFloat(number.toFixed(2));
    }

    created() {
        this.loadData();
    }

    loadData() {
        axios.get(`/api/Dashboard/unearned-revenue`)
            .then((resp) => {
                console.log("loadData() payload:");
                console.log(resp.data)
            }).catch((err) => {
            this.$notification(NotificationOptions.error(err));
        }).finally(() => {
            // this.isLoading = false;
        });
    }

    get status(): DashboardStatus | null {
        if (this.$root.$data.dashboardData) {
            return this.$root.$data.dashboardData as DashboardStatus;
        }
        return new DashboardStatus();
    }

    getWarningLimit(counterName: string): number | null {
        const val = this.status?.getConfigValue(counterName) || '';
        if (isNaN(parseFloat(val))) return null;

        return parseFloat(val);
    }

    getOldestPickUpLimit(_counterName: string): number | null {
        const oldestPickups = this.status?.dispenseStatus.map(ds => moment(ds.oldestPickUp).toDate());
        if (!oldestPickups) return null;
        let isBeyondLimit = false;
        const limitDate = moment().add(-5, 'days').toDate();
        oldestPickups.forEach(element => {
            if (element < limitDate) {
                isBeyondLimit = true;
                return;
            }
        });
        if (isBeyondLimit) return Number.MIN_VALUE;
        return null;
    }

    emitEvent(eventName: string) {
        this.$root.$emit(eventName);
    }

};
