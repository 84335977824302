
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import AnimatedNumber from "@/components/AnimatedNumber.vue";
    import { DashboardStatus } from '@/models/DashboardStatus';
    import { Voicemail, VoicemailGroupCount } from '@/models/Voicemail';
    @Component({
        name: "CounterIndicator",
        components: {
            AnimatedNumber
        }
    })
    export default class CounterIndicator extends Vue {
        @Prop() private name!: string;
        @Prop() private count:number|undefined;
        @Prop() private counts!: Array<DashboardStatus.CountByLocation>;
        @Prop() private countsVM!: Array<Voicemail>;
        @Prop() private routeName!: string;
        @Prop({default: false}) private hideHoverMessage!: boolean;
        @Prop({default: null}) private secondaryCounts!: Array<DashboardStatus.CountByLocation> | null;
        @Prop({default: null}) private VMGroupCount!: Array<VoicemailGroupCount> | null;
        /**
         * If warningLimit not specified, alert will never be triggered.
         */
        @Prop({default: null}) private warningLimit!: number | null;
        @Prop({default: true}) private alertAboveLimit!: boolean;

        get alert():boolean{
            if(this.warningLimit == null) return false;

            if(this.alertAboveLimit) return this.totalCount >= this.warningLimit;
            else return this.totalCount <= this.warningLimit;
        }

        getLinkRoute() {
            return {
                name: this.routeName
            };
        }

        get totalValue():number | null{
            if((this.counts?.filter(c => c.value != null).length ?? 0) == 0){                
                return null;
            }
            return this.counts.reduce((total, c) => { return total + (c.value ?? 0)}, 0.0);
        }

        get totalCount():number{
            if (this.count != undefined) return this.count;
            if(this.name == 'Voice Mail Queue'){
                return this.countsVM.length;
            }
            if(!this.counts?.length) return 0;
            let res = this.counts.map(c => c.count).reduce((a, b) => a+b)
            return res;
        }

        get totalSecondaryCount():number{
            if(!this.secondaryCounts?.length) return 0;
            let res = this.secondaryCounts.map(c => c.count).reduce((a, b) => a+b)
            return res;
        }

        get hoverMessage():string{
            if(this.name == 'Voice Mail Queue'){
                 let resVM = '';
                if(this.VMGroupCount != null){
                      Object.entries(this.VMGroupCount).forEach(
                ([key,value])=>
                resVM +=  value.name + ': ' + value.count + ', '
                )
                return resVM.slice(0,-2);
                }
                return '';
            }
            if(!this.counts?.length || this.hideHoverMessage) return '';
            if(!this.counts.map(c => c.count).reduce((a, b) => a+b)) return ''

            const breakUpByStore = !this.counts.some(c => c.count > 1 && c.otherClassification);
            let res = '';

            if(breakUpByStore) {
                res = this.counts.filter(c => c.storeName).map(c => {
                    let storeAndCount = c.toString();
                    let secondaryCountByStore = this.secondaryCounts?.find(sc => sc.storeId == c.storeId)?.count;
                    if(secondaryCountByStore) storeAndCount+=`(${secondaryCountByStore})`;
                    return storeAndCount;
                }).filter(c => c.length > 0).reduce((a,b) => `${a}<br/>${b}`);
            }
            else res = this.counts.filter(c => c.otherClassification).map(c => c.toString()).filter(c => c.length > 0).reduce((a,b) => `${a}<br/>${b}`);
            return res;
        }
    }

