
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { DashboardStatus } from '@/models/DashboardStatus'
  import { BvTableFieldArray, BTable } from "bootstrap-vue";
   import {Store} from '@/models/Store'
@Component({
        name: "PrintQueueTable"
    })
export default class PrintQueueTable extends Vue {
    @Prop() private status!:Array<DashboardStatus.PrintQueueStatus>;
    @Prop() private sList!: Array<Store>
    get fields(): BvTableFieldArray{
        const fields = [];
        fields.push({ key: "programName", label: "", });
        if(this.sList != null){
           this.sList.forEach(sL => fields.push({key: (sL.name.toLowerCase()).replace(/\s/g, "") + sL.id, label: sL.name }))
        }
        return fields;
    }

    get items(): any[]{
        const items:any = []
        this.status.forEach(st => {
            var obj:any = {};
            obj.programName = st.programName;
            var obj2: any = {};
            st.printQueueRowRecord.forEach(pqr => {
                var uniqueId = pqr.storeName.toLowerCase().replace(/\s/g, "") + pqr.storeId;
                obj[uniqueId] = pqr.resCount;
                if(pqr.dateDays != null ){   
                    if(pqr.dateDays > 4){
                        obj2[uniqueId] = 'danger';
                    }else if(pqr.dateDays > 2 && pqr.dateDays < 4){
                        obj2[uniqueId] = 'warning';
                    }
                }
            })
            obj._cellVariants = obj2;
            items.push(obj);
        })
        return items;
    }
}
